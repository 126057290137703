
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DashboardStatus } from '@/models/DashboardStatus'

    @Component({
        name: "SalesTable"
    })
    export default class SalesTable extends Vue {
        @Prop() private name!: string;
        @Prop() private status!: DashboardStatus.SalesStatus;
        
        private fields:Array<any> = [];
        private sortBy:string = 'name';
        
        created(){
            this.fields = this.getFields();
            if(this.name == 'State')
                this.fields = this.fields.filter(f => f.key != 'shippedOrders')
        }
        
        getFields():Array<any>{
            let res = new Array<any>(
                { key: 'name', label: this.name},
                { key: 'new', label: 'New' },
                { key: 'refill', label: 'Refill', },
                { key: 'totalRx', label: 'TotalRx', },
                { key: 'shippedOrders', label: 'Shipped Orders (Rx)', },
                { key: 'patPay', label: 'PatPay', },
                { key: 'planPaid', label: 'PlanPaid', },
                { key: 'cost', label: 'Cost', },
                { key: 'margin', label: 'Margin', }
            );
            return res;
        }
    }

