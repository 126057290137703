
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import {DashboardShipmentSummary} from '@/models/DashboardShipmentSummary'
    import moment from 'moment';

    @Component({
        name: "SalesTable"
    })
    export default class SalesTable extends Vue {
        @Prop() private name!: string;
        @Prop({ default: () => new DashboardShipmentSummary() }) value!: DashboardShipmentSummary;

        get lastUpdated(): string {
            if (this.$root.$data.dashboardDataUpdated) {
                var date = moment(this.$root.$data.dashboardDataUpdated)
                return date.fromNow() + " @ " + date.toLocaleString();
            }
            return "";
        }

        private fields: any[] = [
            { key:'programName', label: 'Program'}
            , { key: 'totalFills', label: 'Fills' }
            , { key: 'totalRevenue', label: 'Revenue', formatter: this.currencyFormat }
            , { key: 'totalPlanPaid', label: 'Plan Paid', formatter: this.currencyFormat }
            , { key: 'totalVoucherPaid', label: 'Voucher Paid', formatter: this.currencyFormat }
            , { key: 'patientCopay', label: 'Copay', formatter: this.currencyFormat }
            , { key: 'costOfGoods', label: 'COGS', formatter: this.currencyFormat }
            , { key: 'totalTax', label: 'Tax', formatter: this.currencyFormat }
            , { key: 'grossMargin', label: 'Gross Margin', formatter: this.currencyFormat }
            , { key: 'programFee', label: 'Program Fee', formatter: this.currencyFormat }
            , { key: 'consignedDispenseFee', label: 'Consigned Dispense Fee', formatter: this.currencyFormat }
            , { key: 'netMargin', label: 'Net Margin', formatter: this.currencyFormat }
            , { key: 'netMarginPercentage', label: 'Net Margin %', formatter: this.percentFormat }
        ];
        private sortBy: string = 'programName';

        private currencyFormat(value: number) {
            if (value < 0) {
               value = Math.abs(value);
                return "($" + value.toFixed(2)+')';
            } else {
                return "$" + value.toFixed(2);
            }
            
        }
        private percentFormat(value: number) {
            if (value < 0) {
                value = Math.abs(value*100);
                return "("+value.toFixed(2) + "%)";
            } else {
                value *= 100;
                return value.toFixed(2) + "%";
            }
        }
        get items(): any[] {
            const items: any = [];
            this.value.records.forEach(r => {
                r.marginPercentage = r.grossMargin / r.totalRevenue;
                r.netMarginPercentage = r.netMargin / r.totalRevenue;
                r.fillString = "New : " + r.newFills + " Refills: " + r.refills;
                items.push(r);
            })
            return items;
        }
    }
