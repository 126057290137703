
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { DashboardStatus } from '@/models/DashboardStatus'

    @Component({
        name: "BucketsTable"
    })
    export default class BucketsTable extends Vue {
        //@Prop() private status!: DashboardStatus.BucketStatus;
        @Prop({ default: {} as Array< DashboardStatus.BucketStatus> }) private status!:  DashboardStatus.BucketStatus[];
        private fields:Array<any> = [];
       
        created(){
            this.fields = this.getFields();
        }
        
        getFields():Array<any>{
            let res = new Array<any>(
                { key: 'name', label: "Name", },
                { key: 'escript', label: 'Escript' },
                { key: 'image', label: 'Image', },
                { key: 'programTransfer', label: 'Program Transfer', },
                { key: 'total', label: 'Total', }
            );
            return res;
        }
        
    }
    
