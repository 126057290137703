
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';
import HeaderChart from "@/pages/Dashboard/Dashboard/HeaderChart"
import { DashboardHistoryRecord } from "@/models/DashboardHistoryRecord";

@Component({
  name: "DashboardHeader",
  components: {
    HeaderChart
  }
})
export default class DashboardHeader extends Vue {
  private chartData: Array<number> = [];
  private chartLabels: Array<string> = [];
  private chartTitle = 'Processed Scripts By Week'
  private format: string = "number";
  mounted() {
    this.loadChartData('opened-rxs-history');

    this.$root.$on('totalScriptsTodayClicked', () => {
      this.chartTitle = 'Processed Scripts By Week';
      this.loadChartData('opened-rxs-history');
    })

    this.$root.$on('totalTodayRevenueClicked', () => {
      this.chartTitle = 'Total Revenue By Week';
      this.loadChartData('revenue-history');
    })

    this.$root.$on('totalOpenOrdersClicked', () => {
      this.chartTitle = 'Created Orders By Week';
      this.loadChartData('opened-orders-history');
    })

    this.$root.$on('totalOrdersShippedTodayClicked', () => {
      this.chartTitle = 'Shipped Orders By Week';
      this.loadChartData('shipped-orders-history');
    })

  }

  loadChartData(endpoint: string) {

    this.$http.get<DashboardHistoryRecord[][]>('dashboard/' + endpoint)
      .then(res => {
        if (res.data) {
          this.chartLabels = res.data.map(p => moment(p[0].date).format('MMM-DD'));
          this.chartData = res.data.map(p => p.reduce((a: any, b: any) => a + b.quantity, 0));
          if (endpoint == "revenue-history") {
            this.format = "currency";
          }
          else {
            this.format = "number";
          }
        }
      })
      .catch(err => {
        console.log('Error while loading total quantities in dashboard', { err, response: err.data?.response })
      })

  }
}
