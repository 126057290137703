
import CounterIndicator from "@/components/Dashboard/CounterIndicator.vue";
import SalesTable from "@/components/Dashboard/SalesTable.vue";
import ShipmentSummaryReport from "@/components/Dashboard/ShipmentSummaryReport.vue";
import BucketsTable from "@/components/Dashboard/BucketsTable.vue";
import PrintQueueTable from "@/components/Dashboard/PrintQueueTable.vue";
import { DashboardStatus } from "@/models/DashboardStatus";
import { Component, Vue } from 'vue-property-decorator';
import AnimatedNumber from "@/components/AnimatedNumber.vue";
import moment from "moment";
import { BvTableProviderCallback } from "bootstrap-vue";
import { NotificationOptions } from "@/util/NotificationOptionsPresets";
import axios from "axios";
import DispenseStatusComponent from "@/pages/Dashboard/Dashboard/DispenseStatusComponent.vue";
import draggable from 'vuedraggable';

@Component({
    components: {
        DispenseStatusComponent,
        CounterIndicator, SalesTable, BucketsTable, AnimatedNumber, ShipmentSummaryReport, PrintQueueTable, draggable
    }
})
export default class Dashboard extends Vue {

    private enabledParts: Array<string> = [];
    private availableCards: Array<[string, string]> = [
        ["dispensingStatusCard", "Card: Dispensing Status"],
        ["processingStatusCard", "Card: Processing Status"],
        ["workQueueCard", "Card: Work Queue Status"],
        ["printQueueCard", "Card: Print Queue Status"],
        ["salesStatusCard", "Card: Sales Status"],
    ];
    private availableIndicators: Array<[string, string]> = [
        ["insuranceQueueCount", "Indicator: Insurance Queue"],
        ["allergyQueueCount", "Indicator: Allergy Queue"],
        ["onlineOrdersQueueCount", "Indicator: Online Orders Queue"],
        ["rxTransferQueueCount", "Indicator: Rx Transfer Queue"],
        ["openCasesQueueCount", "Indicator: Open Cases Queue"],
        ["consultationsQueueCount", "Indicator: Consultations Queue"],
        ["pendingFollowUpQueueCount", "Indicator: Doctor Follow Up Queue"],
        ["autoRefillQueueCount", "Indicator: Autorefill Queue"],
        ["consignmentQueueCount", "Indicator: Consignment Queue"],
        ["pendingBillingQueueCount", "Indicator: Pending Billing Queue"],
        ["voicemails", "Indicator: Voicemail Queue"],
        ["partialPaymentQueueCount", "Indicator: Partial Payment Queue"],
        ["reversalQueueCount", "Indicator: Reversal Queue"],
        ["returnedOrders", "Indicator: Returned Packages Queue"],
        ["patientAssistanceProgramQueueCount", "Indicator: Patient Assistance Program Queue"],
        ["priorAuthorizationQueueCount", "Indicator: Prior Authorization Queue"],
        ["patientMergeRequestQueueCount", "Indicator: Patient Merge Request Queue"],
        ["expiredOnOrderQueueCount", "Indicator: Expired on Order Queue"]
    ];
    created() {
        this.loadData();
        this.retrieveEnabledParts();
    }

    loadData() {
        axios.get(`/api/Dashboard/unearned-revenue`)
            .then((resp) => {
                console.log("loadData() payload:");
                console.log(resp.data)
            }).catch((err) => {
                this.$notification(NotificationOptions.error(err));
            }).finally(() => {
                // this.isLoading = false;
            });
    }

    get status(): DashboardStatus {
        if (this.$root.$data.dashboardData) {
            return this.$root.$data.dashboardData as DashboardStatus;
        }
        return new DashboardStatus();
    }

    closeEnabledPartsModal() {
        this.retrieveEnabledParts();
        this.$bvModal.hide('dashboardPreference');
    }

    saveEnabledParts() {
        localStorage.setItem("enabledDashboardParts", JSON.stringify(this.enabledParts.sort((p: any, p2: any) => this.availableCards.map((a: any) => a[0]).indexOf(p) - this.availableCards.map((a: any) => a[0]).indexOf(p2))));
        this.$bvModal.hide('dashboardPreference');
    }

    retrieveEnabledParts() {
        let savedParts = localStorage.getItem("enabledDashboardParts");

        if (savedParts) {
            this.enabledParts = JSON.parse(savedParts);
        }
        else {
            this.enabledParts = this.availableCards.map((part) => part[0]);
        }
    }

    isPartEnabled(partName: string) {
        return this.enabledParts.indexOf(partName) >= 0 || this.enabledParts.length == 0;
    }

    getWarningLimit(counterName: string): number | null {
        const val = this.status?.getConfigValue(counterName) || '';
        if (isNaN(parseFloat(val))) return null;

        return parseFloat(val);
    }

    getOldestPickUpLimit(_counterName: string): number | null {
        const oldestPickups = this.status?.dispenseStatus.map(ds => moment(ds.oldestPickUp).toDate());
        if (!oldestPickups) return null;
        let isBeyondLimit = false;
        const limitDate = moment().add(-5, 'days').toDate();
        oldestPickups.forEach(element => {
            if (element < limitDate) {
                isBeyondLimit = true;
                return;
            }
        });
        if (isBeyondLimit) return Number.MIN_VALUE;
        return null;
    }

    emitEvent(eventName: string) {
        this.$root.$emit(eventName);
    }

};
